<template>
  <div class="notifyList">
    <!-- <div class="notifyList__wrapper" v-if="isAverage">
      <div class="notifyList__averageCards" v-for="({ name, role, avg }, index) in averages" :key="index">
        <div class="notifyList__averageCard">
          <p class="notifyList__averageDate">
            <strong class="notifyList__averageText">{{ name }}</strong>
            -
            <i class="notifyList__averageRole">{{ rolesDictionary[role] }}</i>
          </p>
          <p class="notifyList__averageDate">
            <strong class="notifyList__averageText">{{ role === "model" ? "Promedio horas en MySpace:" : "Promedio ingreso a MySpace:" }}</strong>
            {{ avg }}
          </p>
        </div>
      </div>
    </div> -->
    <div v-if="notify.length > 0">
      <div class="notifyList__items" v-for="(item, index) in notify" :key="index">
        <div class="notifyList__msg" v-html="showMessageList(item)"></div>
        <div class="notifyList__date">{{ formateDate(item.date) }}</div>
      </div>
    </div>
    <div v-else>
      <p v-if="!isLoading" class="noFoundInfo">No hay registros para esta consulta.</p>
    </div>
    <div class="spinner" v-if="isLoading"></div>
    <div class="notifyList__btn" v-if="total <= 20 && !isLoading">
      <button class="click-on-bottom notifyList__showMore" @click="getNotifications((cpage += 1))">
        Ver más
      </button>
    </div>
  </div>
</template>

<script>
import { showMessage, dateLocaleFormat } from "@/js/utils";
export default {
  data() {
    return {
      notify: [],
      averages: [],
      optionFormat: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      },
      cpage: 1,
      total: "",
      rolesDictionary: this.$global.dictionary.roles_es_auto,
      isLoading: true,
    };
  },
  computed: {
    isAverage() {
      return this.averages?.length;
    },
    isNotifications() {
      return this.notify?.length;
    },
  },
  mounted() {
    this.$root.$off("dataQuery");
    this.$root.$off("appendQuery");
    this.$root.$on("dataQuery", ({ allNotify, total, resAvgUsers }) => {
      this.isLoading = false;
      this.cpage = 1;
      this.notify = allNotify;
      this.total = total;
      this.averages = [];
      this.averages = resAvgUsers?.totalAvg;
    });
    this.$root.$on("appendQuery", ({ allNotify }) => {
      this.isLoading = false;
      this.notify.push(...allNotify);
    });
    this.$root.$on("showLoader", () => {
      this.notify = [];
      this.isLoading = true;
    })
  },
  methods: {
    getNotifications(page) {
      this.isLoading = true;
      this.$root.$emit("getNotifications", page);
    },
    showMessageList(item) {
      return showMessage(item, this.$userData, true);
    },
    formateDate(date) {
      return dateLocaleFormat(date, this.optionFormat);
    },
  },
};
</script>

<style lang="scss">
.notifyList {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  &__items,
  &__averageCards {
    padding: $mpadding 10px;
    margin-bottom: 5px;
    background: $white;
    border-radius: 5px;
    box-shadow: $dshadow;
    &:hover {
      background: $porcelain;
    }
  }
  &__averageCards {
    margin-bottom: $mpadding * 2;
    width: 100%;
    font-size: 1em;
    background-color: $porcelain;
  }
  &__averageRole {
    text-transform: capitalize;
  }
  &__averageCard {
    width: 100%;
    text-align: center;
  }
  &__showMore {
    border: none;
    outline: none;
    color: $lipstick;
    background: transparent;
    margin-top: $mpadding;
    &:focus {
      border: none;
      outline: none;
      text-decoration: none;
    }
  }
  &__date {
    white-space: nowrap;
    margin-left: 10px;
    font-size: 1em;
    opacity: 0.95;
    color: $lipstick;
    font-family: $sec_font;
  }
  &__msg {
    font-size: 1em;
    font-family: $first_font;
  }
  &__btn {
    text-align: center;
  }
}
@media (min-width: ($mobile_width+1px)) {
  .notifyList {
    &__items {
      display: flex;
      justify-content: space-between;
    }
    &__msg {
      max-width: $mobile_width + 70;
    }
  }
}
@media (min-width: $pocket_width) {
  .notifyList {
    &__averageCard {
      @include Flex(row, space-between);
    }
  }
}
@media (min-width: $tablet_width) {
  .notifyList {
    padding: 5px 25px;
  }
}
@media (min-width: ($desktop_width+200px)) {
  .notifyList {
    &__msg {
      max-width: $tablet_width;
    }
  }
}
</style>
