<template>
  <div class="notificationsNav">
    <div class="wrapShow" @click="maskNotifications"></div>
    <div class="notificationsNav__total">
      <h2 class="notificationsNav__titlePage">Notificaciones</h2>
    </div>
    <div class="notificationsNav__nav">
      <div class="notificationsNav__btns">
        <div class="notificationsNav__btn notificationsNav__btn--search">
          <Search class="notificationsNav__Search" ref="search" @click.native="toggleSearch" />
        </div>
        <div class="notificationsNav__btn" @click="$refs.myDatePicker.togglePicker()">
          <i class="notificationsNav__icon fas fa-calendar-alt"></i>
          <span class="notificationsNav__text">Fecha</span>
        </div>
        <div class="notificationsNav__options">
          <div v-for="(option, key) in options" :key="key" class="notificationsNav__btn" @click="initNotifications(option.method)">
            <i :class="[option.icon, 'notificationsNav__icon']"></i>
            <span class="notificationsNav__text">{{ option.text }}</span>
          </div>
          <div class="notificationsNav__btn" @click="initNotifications('reset')">
            <i :class="['fas fa-times', 'notificationsNav__icon']"></i>
            <span class="notificationsNav__text">Quitar filtros</span>
          </div>
        </div>
      </div>
    </div>
    <div class="notificationsNav__dropdown">
      <div class="notificationsNav__dropdownItem notificationsNav__dropdownItem--left">
        <b-dropdown id="dropdown-1" class="m-md-2">
          <template #button-content class="notificationsNav__title">
            <i :class="defaultClass"></i>
            {{ defaultText }}
          </template>
          <b-dropdown-item v-for="(option, key) in options" :key="key" @click="changeOption(option.method)">
            <i :class="['notificationsNav__icon', option.icon]"></i>
            {{ option.text }}
          </b-dropdown-item>
        </b-dropdown>
        <div v-show="isFilters" class="notificationsNav__btn notifications__btn--close" @click="changeOption('reset')">
          <i class="notificationsNav__icon fas fa-times"></i>
          <span class="notificationsNav__text">Quitar filtros</span>
        </div>
      </div>
    </div>
    <v-md-date-range-picker
      class="calendarColor"
      ref="myDatePicker"
      @change="handleDate"
      :start-date="dateFrom"
      :end-date="dateTo"
    ></v-md-date-range-picker>
  </div>
</template>

<script>
import axios from "@/api/axios";
import Search from "@/components/search/Search.vue";
import setFilters from "../../mixins/setfilter";
export default {
  components: {
    Search,
  },
  mixins: [setFilters],
  data() {
    return {
      isFilters: false,
      notifications: [],
      dateFrom: undefined,
      dateTo: undefined,
      type: "",
      page: "",
      userQuery: "",
      users: "",
      defaultText: "Todas las notificaciones ",
      defaultClass: "",
      options: [
        {
          icon: "fas fa-project-diagram",
          method: "seeStats",
          text: "Plataformas",
        },
        {
          icon: "fas fa-sign-in-alt",
          method: "Login",
          text: "Inicio de Sesión",
        },
        {
          icon: "fas fa-door-open",
          method: "Logout",
          text: "Cierre de Sesión",
        },
        {
          icon: "fas fa-eye",
          method: "Review",
          text: "Revisión Habitación",
        },
        {
          icon: "fas fa-video",
          method: "Streaming",
          text: "Streaming",
        },
        {
          icon: "fas fa-bullseye",
          method: "Goals",
          text: "Metas",
        },
        {
          icon: "fas fa-book",
          method: "assignViewReportMonitor",
          text: "Reportes",
        },
        {
          icon: "fas fa-tools",
          method: "maintenenceRoom",
          text: "Mantenimiento",
        },
      ],
      default: {
        seeStats: {
          defaultText: "Plataformas ",
          defaultClass: "notificationsNav__icon fas fa-project-diagram",
        },
        Login: {
          defaultText: "Inicio de Sesión ",
          defaultClass: "notificationsNav__icon fas fa-sign-in-alt",
        },
        Logout: {
          defaultText: "Cierre de Sesión ",
          defaultClass: "notificationsNav__icon fas fa-door-open",
        },
        Review: {
          defaultText: "Revisión Habitación ",
          defaultClass: "notificationsNav__icon fas fa-eye",
        },
        Streaming: {
          defaultText: "Streaming ",
          defaultClass: "notificationsNav__icon fas fa-video",
        },
        Goals: {
          defaultText: "Metas ",
          defaultClass: "notificationsNav__icon fas fa-bullseye",
        },
        assignViewReportMonitor: {
          defaultText: "Reportes ",
          defaultClass: "notificationsNav__icon fas fa-book",
        },
        reset: {
          defaultText: "Todas las notificaciones ",
          defaultClass: "",
        },
        all: {
          defaultText: "Todas las notificaciones ",
          defaultClass: "",
        },
        maintenenceRoom: {
          defaultText: "Mantenimiento ",
          defaultClass: "notificationsNav__icon fas fa-tools",
        },
      },
    };
  },
  methods: {
    hideFilters(reset) {
      if (reset) {
        this.isFilters = false;
        return;
      }
      if (!(this.type == "" || this.type == "all")) {
        this.isFilters = true;
      }
      if (this.dateFrom && this.dateTo) {
        this.isFilters = true;
      }
    },
    changeOption(type) {
      this.changeDefaultText(type);
      this.initNotifications(type);
    },

    changeDefaultText(type) {
      if (type) {
        this.defaultText = this.default[type].defaultText;
        this.defaultClass = this.default[type].defaultClass;
      }
    },

    maskNotifications() {
      this.$refs.search.initListUser();
      this.toggleSearch();
    },

    toggleSearch() {
      const wrap = document.querySelector(".wrapShow");
      wrap.classList.toggle("wrapShow-active");
      if (!wrap.classList.contains("wrapShow-active")) {
        this.$refs.search.initListUser();
      }
    },

    initNotifications(type) {
      this.page = 1;
      this.getNotifications(type);
    },

    async getNotifications(value) {
      if (this.page == 1) {
        this.$root.$emit("showLoader");
      }
      this.type = value;
      this.hideFilters();
      if (this.type === "all") {
        if (!this.dateFrom) {
          this.dateFrom = undefined;
          this.dateTo = undefined;
        }
      }
      if (this.type === "reset") {
        this.hideFilters(true);
        this.type = "all";
        this.users = "";
        this.dateFrom = undefined;
        this.dateTo = undefined;
        this.$refs.search.resetSearch();
        this.consultNotifications();
        window.history.replaceState({}, document.title, "/notifications");
        return;
      }
      if (this.type == "users") {
        this.hideFilters();
        this.type = "all";
        this.dateFrom = undefined;
        this.dateTo = undefined;
        this.changeDefaultText("reset");
      }
      const queryDate = `${this.dateFrom ? `&dateFrom=${this.dateFrom}` : ""}${this.dateTo ? `&dateTo=${this.dateTo}` : ""}`;
      const options = {
        type: this.type,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        users: this.users,
        view: "report",
      };
      this.saveSearch(options);
      this.consultNotifications(queryDate);
    },

    handleDate: async function(data, values) {
      this.hideFilters();
      this.dateFrom = values[0];
      this.dateTo = values[1];
      await this.getNotifications(this.type);
    },

    saveSearch(options) {
      for (const option in options) {
        if (options[option]) {
          this.setFilter({
            srcElement: {
              name: option,
              value: options[option],
            },
          });
        }
      }
    },

    getParams() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      return params;
    },

    async updatePage(params) {
      for (const param in params) {
        this[param] = params[param];
      }
      this.page = 1;
      await this.getNotifications(this.type);
    },

    activeButton() {
      const active = document.querySelectorAll(".notificationsNav__btn");
      active.forEach((e) => {
        e.addEventListener("click", () => {
          active.forEach((item) => {
            item.classList.remove("activeNotify");
          });
          e.classList.add("activeNotify");
        });
      });
    },
    async consultNotifications(queryDate) {
      const res = await axios.get(`/notifications/?type=${this.type}${queryDate ? queryDate : ""}&page=${this.page}&users=${this.users}&view=report`);
      this.notifications = res.data;
      if (this.page > 1) {
        this.$root.$emit("appendQuery", this.notifications);
      } else {
        this.$root.$emit("dataQuery", this.notifications);
      }
    },
  },
  async mounted() {
    this.$root.$on("getNotifications", async (page) => {
      this.page = page;
      await this.getNotifications(this.type);
    });

    this.$root.$on("sendUserQuery", async (model) => {
      this.users = model;
      await this.getNotifications("users");
    });

    const params = this.getParams();
    this.updatePage(params);
    this.activeButton();
    this.changeDefaultText(params.type);
    this.$refs.search.searchValue = params.users;
  },
};
</script>

<style lang="scss">
.wrapShow {
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 50;
  background: rgba(0, 0, 0, 0.1);
  &-active {
    display: block;
  }
}
.activeNotify {
  border-radius: 5px;
  background: $porcelain;
}

.notificationsNav {
  max-width: 1024px;
  margin: 0 auto;
  height: 110px;
  &__options {
    @include Row(flex-start);
    width: 100%;
    margin: auto;
  }
  &__title {
    font-size: 600;
  }
  &__nav {
    display: flex;
    min-height: 40px;
    margin-top: 15px;
    border-radius: 5px;
    font-family: $first_font;
    font-size: $label-forms;
    background: $white;
    box-shadow: 0 2px 5px $alto;
  }

  &__total {
    font-size: 1.2em;
    margin: 20px auto;
    text-align: center;
    color: $chicago;
  }

  &__btns {
    @include Row(flex-start);
    width: 100%;
    max-width: 974px;
    margin: auto;
    overflow-x: auto;
  }
  &__btn {
    padding: 0 $mpadding;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    max-height: 40px;
    text-align: center;
    color: $lipstick;
    border: none;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
    &--search {
      z-index: 52;
      padding: 10px $mpadding;
    }
    &--average {
      display: none;
    }
  }
  &__icon {
    padding: $mpadding/2;
  }
  &__text {
    white-space: nowrap;
    font-weight: 600;
  }
  .mdrp__activator {
    display: none;
  }
  .mdrp__panel.dropdown-menu[data-v-7863e830] {
    margin-top: -45px;
  }
  .mdrp__panel .calendar-table.right[data-v-7863e830] {
    display: none;
  }
  .calendarColor {
    color: $black;
    font-family: $first_font;
  }
  table.calendar-table td.calendar-cell.active .calendar-cell__content[data-v-89ba891e],
  table.calendar-table td.calendar-cell.active:hover .calendar-cell__content[data-v-89ba891e] {
    background-color: $lipstick;
  }
  .ranges ul[data-v-4e185066] .active {
    color: $lipstick;
  }
  &__dropdownItem {
    width: fit-content;
    @include Flex(row, flex-start);
    &--right {
      justify-content: flex-end;
    }
  }
  &__dropdown {
    display: none;
    left: 280px;
    position: relative;
    top: -45px;
    max-height: 50px;
    align-items: center;
    #dropdown-1 {
      max-height: 40px;
    }
    .dropdown-item {
      padding: 4px $mpadding/2;
      color: $lipstick;
    }
    .btn-secondary {
      padding: 6px $mpadding/2;
      color: $lipstick;
      background: white;
      border: white;
      box-shadow: none;
      display: flex;
      align-items: center;
      font-weight: 600;
    }
    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
      color: $lipstick;
      background: white;
      border: white;
      box-shadow: none;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: white;
    }
  }
}
@media (min-width: $tablet_width) {
  .notificationsNav {
    padding: 0 25px;
    .mdrp__panel.dropdown-menu[data-v-7863e830] {
      margin-top: -55px;
      left: -320px;
    }
    &__options {
      display: none;
    }
    &__dropdown {
      display: inline-flex;
    }
    &__btn {
      padding: 0 $mpadding/3;
      &--close {
        padding-left: 0;
      }
      &--average {
        display: inline-flex;
      }
    }
  }
}
@media (min-width: $desktop_width) {
  .notificationsNav {
    &__titlePage {
      text-align: center;
      font-size: 40px;
    }
    &__dropdown {
      left: 260px;
    }
    &__btn {
      padding: 0 8px;
    }
    &__btn {
      &--close {
        padding-left: $mpadding/2;
      }
    }
  }
}
</style>
