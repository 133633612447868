<template>
  <section class="NotificationsView">
    <notifyNav />
    <NotificationList />
  </section>
</template>

<script>
import notifyNav from "@/components/notifications/NotifyNav.vue";
import NotificationList from "@/components/notifications/NotificationList.vue";
export default {
  components: {
    notifyNav,
    NotificationList,
  },
};
</script>
<style lang="scss">
@media (min-width: $tablet_width) {
  .NotificationsView {
    min-width: 730px;
  }
}
@media (min-width: $desktop_width) {
  .NotificationsView {
    max-width: calc(100vw - 330px);
  }
}
</style>
